import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {Club} from "~/models/club";
import type {AddStudentIntoClubRb} from "~/models/request/addStudentIntoClubRb";
import {ClubType} from "~/utils/enums/ClubType";
import type {GetClubsByStudentIdRb} from "~/models/request/getClubsByStudentIdRb";
import {ClubStatus} from "~/utils/enums/ClubStatus";
import type {GetClubDetailsByClubIdRb} from "~/models/request/getClubDetailsByClubIdRb";
import type {AddUpdateClubRb} from "~/models/request/addUpdateClubRb";
import type {DeleteStudentFromClubRb} from "~/models/request/deleteStudentFromClubRb";
import type {GetEventStudentsWithTicketsRb} from "~/models/request/getEventStudentsWithTicketsRb";
import type {Contest} from "~/models/contest";
import type {ClubRegistrationAnswer} from "~/models/ClubRegistrationAnswer";
import type {LoadAllAnswersOfAClubRb} from "~/models/request/loadAllAnswersOfAClubRb";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import type {ClubRegQuestionAnswerRb} from "~/models/request/ClubRegQuestionAnswerRb";
import type {AddClubCategoryRb} from "~/models/request/AddClubCategoryRb";
import type {ClubCategory} from "~/models/ClubCategory";
import type {DeleteWaitlistedStudentsRb} from "~/models/request/WaitlistedStudents/DeleteWaitlistedStudentsRb";
import type {InviteSelectedWaitlistsRB} from "~/models/request/WaitlistedStudents/InviteSelectedWaitlistStudentsRb";

export const clubStore = defineStore({
    id: 'club-store',
    persist: true,
    state: () => {
        return {
            clubs: <Club[]>[],
            currentClub: <Club>{},
            locationMap: <any>{},
            clubCategoryMap: <any>{},
            participatedEventsForLoggedInUser: <Club[]>[],
            rsvpGuestEvent: <Club>{},
            allAnswersForCurrentClub: new Map<number, ClubRegistrationAnswer[]>(),
            allAnswersForCurrentCompany: new Map<number, Map<number, ClubRegistrationAnswer[]>>()
        }
    },
    actions: {
        clearClubStore: function () {
            this.clearClubs();
            this.clearParticipatedEventsForLoggedInUser();
            this.clearRsvpGuestEvent();
            this.clearAllAnswersForCurrentClub();
            this.clearAllAnswersForCurrentCompany();
            this.currentClub = <Club>{};
        },
        clearClubs: function () {
            this.clubs = [];
        },
        clearParticipatedEventsForLoggedInUser: function (){
            this.participatedEventsForLoggedInUser = [];
        },
        clearRsvpGuestEvent: function () {
            this.rsvpGuestEvent = <Club>{};
        },
        clearAllAnswersForCurrentClub: function () {
            this.allAnswersForCurrentClub = new Map<number, ClubRegistrationAnswer[]>();
        },
        clearAllAnswersForCurrentCompany: function () {
            this.allAnswersForCurrentCompany = new Map<number, Map<number, ClubRegistrationAnswer[]>>();
        },
        setAnAnswerForCurrentClub: function (studentId: number, answers: ClubRegistrationAnswer[]){
            this.allAnswersForCurrentClub.set(studentId, answers);
        },
        setAnAnswerForCurrentCompany: function (clubId: number, studentId: number, answers: ClubRegistrationAnswer[]){
            let studentAnswersMap = this.allAnswersForCurrentCompany.get(studentId);
            if(!studentAnswersMap){
                studentAnswersMap = new Map<number, ClubRegistrationAnswer[]>();
            }
            studentAnswersMap?.set(clubId, answers);
            this.allAnswersForCurrentCompany.set(studentId, studentAnswersMap);
        },
        getAllClubsByCompanyId: async function (companyId:any) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/GetClubList';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"companyId": Number(companyId)}, null, "getClubList" + new Date().getMilliseconds() + "_")
            if((Result?.length??-1)>0){
                this.clubs = [];
                for(const item of Result){
                    this.clubs.push(item as Club);
                }
            }
        },
        addStudentIntoClub: async function (studentClubRb:AddStudentIntoClubRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/AddStudentToClubs';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, studentClubRb, null, "addStudentIntoClub" + new Date().getMilliseconds() + "_")
        },
        addStudentIntoClubServer: async function (studentClubRb:AddStudentIntoClubRb) {
            const config = useRuntimeConfig()
            let url = '/api/club/add-student-into-club';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, studentClubRb, null, "addStudentIntoClubServer" + new Date().getMilliseconds() + "_")
        },
        deleteStudentFromClub: async function (deleteStudentFromClubRb:DeleteStudentFromClubRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/DeleteStudentFromClub';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, deleteStudentFromClubRb, null, "deleteStudentFromClub" + new Date().getMilliseconds() + "_")
        },
        loadClubsByStudentId: async function (getClubsByStudentIdRb:GetClubsByStudentIdRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/GetAllClubsByStudentId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getClubsByStudentIdRb, null, "loadClubsByStudentId" + new Date().getMilliseconds() + "_")
            const {Clubs} = Result??{}
            return Clubs;
        },
        getAllLocationsByCompanyId: async function (companyId:number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Location/getLocationsByCompanyId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {'CompanyId': companyId}, null, "getLocationsByCompanyId" + new Date().getMilliseconds() + "_")
            if(Array.isArray(Result) && Result.length > 0){
                for(const location of Result) this.locationMap[location.id] = location
            }
        },
        addClubCategories: async function (addClubCategoryRb:AddClubCategoryRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/ClubCategory/addUpdateClubCategory';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, addClubCategoryRb, null, "addCategoriesToClub" + new Date().getMilliseconds() + "_")
            return Result;
        },
        insertIntoClubCategoryLocal: function(clubCategory: ClubCategory){
            this.clubCategoryMap[clubCategory.id] = clubCategory
        },
        getClubCategoriesByCompanyId: async function (companyId:number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/ClubCategory/getClubCategoriesByCompanyId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {'CompanyId': companyId}, null, "getClubCategoriesByCompanyId" + new Date().getMilliseconds() + "_")
            console.log(Result)
            if(Array.isArray(Result) && Result.length > 0){
                for(const clubCategory of Result) this.clubCategoryMap[clubCategory.id] = clubCategory
            }
            console.log(this.clubCategoryMap)
        },
        getClubDetailsById: async function (getClubDetailsRb:GetClubDetailsByClubIdRb){
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Club/getClubDetails';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, getClubDetailsRb, null, "getClubDetailsById" + new Date().getMilliseconds() + "_")
                if(Result?.id){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },
        getClubDetailsByIdFromServer: async function (getClubDetailsRb:GetClubDetailsByClubIdRb){
            try{
                const config = useRuntimeConfig()
                let url = '/api/club/details';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, getClubDetailsRb, null, "getClubDetailsByIdFromServer" + new Date().getMilliseconds() + "_")
                if(Result?.id){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },
        deleteWaitListedStudentsByClubId: async function (toDeleteWaitlistedStudents: DeleteWaitlistedStudentsRb){
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Club/deleteWaitlistedStudents';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, toDeleteWaitlistedStudents , null, "deleteWaitListedStudents" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },
        getAllWaitListedStudentsByClubId: async function (clubId: number, companyId: number){
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Club/getAllWaitListedStudentsByClubId';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, { ClubId: clubId, CompanyId: companyId }, null, "getAllWaitListedStudentsByClubId" + new Date().getMilliseconds() + "_")
                if(Result){
                    return Result;
                }
            }catch (e){
                console.log(e);
            }
        },
        addUpdateClub:  async function (addUpdateClubRb:AddUpdateClubRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Club/CreateUpdateClub';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"club": addUpdateClubRb}, null, "addUpdateClub" + new Date().getMilliseconds() + "_")
            return Result;
        },

        loadAndSetParticipatedEventsForLoggedInUser: async function(getClubsByStudentIdRb:GetClubsByStudentIdRb){
            try{
                this.clearParticipatedEventsForLoggedInUser();
                const response = await this.loadClubsByStudentId(getClubsByStudentIdRb);
                for(const rawClub of response){
                    this.participatedEventsForLoggedInUser.push(rawClub as Club);
                }
            }catch (e) {
                console.log(e)
            }
        },

        getEventStudentsWithTickets: async function (getEventStudentsWithTicketsRb:GetEventStudentsWithTicketsRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getEventStudentsWithTicketsById';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getEventStudentsWithTicketsRb, null, "getEventStudentsWithTickets" + new Date().getMilliseconds() + "_")
            return Result??[];
        },
        loadAndSetRsvpGuestEventFromServer: async function(getClubDetailsRb:GetClubDetailsByClubIdRb){
            const clubResponse = await this.getClubDetailsByIdFromServer(getClubDetailsRb);
            if(clubResponse){
                this.setRsvpGuestEvent(clubResponse as Club);
            }
        },
        setRsvpGuestEvent: function (event: Club) {
            this.rsvpGuestEvent = event;
        },
        setCurrentClub: function (club: Club) {
            this.currentClub = club;
        },
        removeContestFromClub: function (clubId: number, contestId: number) {
            const foundClub = this.clubs.find((item:Club)=>item.id==clubId);
            if(foundClub){
                foundClub.Contests = foundClub.Contests.filter((item:Contest)=>item.id!=contestId);
            }
        },
        addContestIntoClub: function (clubId: number, contest:Contest) {
            const foundClub = this.clubs.find((item:Club)=>item.id==clubId);
            if(foundClub){
                this.removeContestFromClub(clubId, contest.id);
                foundClub.Contests.push(contest);
            }
        },

        submitClubRegistrationAnswerServer: async function (clubRegQuestionAnswerRb:ClubRegQuestionAnswerRb, fromServer:boolean = false) {
            try{
                const config = useRuntimeConfig()
                let url;
                if(fromServer){
                    url = '/api/club-registration/club-registration-question-answer';
                }else{
                    url = config.public.BASE_URL_SOPS + '/api/clubRegistration/saveUpdateBulkAnswers';
                }
                const ns = new NetworkService();
                let {IsOperationSuccessful, ErrorMessages, Result} = await ns.post$fetch(url, clubRegQuestionAnswerRb, null, "submitClubRegistrationAnswerServer" + new Date().getMilliseconds() + "_", false, fromServer);
                if(IsOperationSuccessful){
                    return Result;
                }else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error submitting answers',
                        message: Result+' : '+ErrorMessages,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },

        loadAllAnswerForCurrentClub: async function (loadAllAnswersOfAClubRb:LoadAllAnswersOfAClubRb, fromServer:boolean = false) {
            try{
                const config = useRuntimeConfig()
                let url;
                if(fromServer){
                    url = '/api/club-registration/load-all-club-registration-answers';
                }else{
                    url = config.public.BASE_URL_SOPS + '/api/clubRegistration/getAnswersByClubId';
                }
                const ns = new NetworkService();
                let {IsOperationSuccessful, ErrorMessages, Result} = await ns.post$fetch(url, loadAllAnswersOfAClubRb, null, "loadAllAnswerForCurrentClubServer" + new Date().getMilliseconds() + "_", false, fromServer);
                if(IsOperationSuccessful){
                    return Result;
                }else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error fetching answers',
                        message: Result+' : '+ErrorMessages,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },

        loadAndSetAllAnswersForCurrentClub: async function(loadAllAnswersOfAClubRb:LoadAllAnswersOfAClubRb, fromServer:boolean = false){
            try{
                let response = await this.loadAllAnswerForCurrentClub(loadAllAnswersOfAClubRb, fromServer);
                if(response){
                    this.clearAllAnswersForCurrentClub();
                    const studentIds = Object.keys(response??{});
                    for(const studentId of studentIds){
                        this.setAnAnswerForCurrentClub(Number(studentId), response[studentId]);
                    }
                }
            }catch (e) {
                console.log(e);
            }
            return this.allAnswersForCurrentClub;
        },

        loadAllAnswerForCurrentCompany: async function (companyId:number, fromServer:boolean = false) {
            try{
                const config = useRuntimeConfig()
                let url;
                if(fromServer){
                    url = '/api/club-registration/load-all-company-registration-answers';
                }else{
                    url = config.public.BASE_URL_SOPS + '/api/clubRegistration/getAnswersByCompanyId';
                }
                const ns = new NetworkService();
                let {IsOperationSuccessful, ErrorMessages, Result} = await ns.post$fetch(url, {CompanyId: companyId}, null, "loadAllAnswerForCurrentCompany" + new Date().getMilliseconds() + "_", false, fromServer);
                if(IsOperationSuccessful){
                    return Result;
                }else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error fetching answers',
                        message: Result+' : '+ErrorMessages,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },

        loadAndSetAllAnswersForCurrentCompany: async function(companyId:number, fromServer:boolean = false){
            try{
                let response = await this.loadAllAnswerForCurrentCompany(companyId, fromServer);
                if(response){
                    this.clearAllAnswersForCurrentCompany();
                    const studentIds = Object.keys(response??{});
                    for(const studentId of studentIds){
                        const clubWiseAnswers = response[studentId]??{};
                        const clubIds = Object.keys(clubWiseAnswers);
                        for(const clubId of clubIds){
                            this.setAnAnswerForCurrentCompany(Number(clubId), Number(studentId), clubWiseAnswers[clubId]??{});
                        }
                    }
                }
            }catch (e) {
                console.log(e);
            }
            return this.allAnswersForCurrentClub;
        },
        getQuestionsByCompanyId: async function (companyId:number, fromServer:boolean = false) {
            try{
                const config = useRuntimeConfig()
                let url;
                if(fromServer){
                    url = '/api/club-registration/load-all-company-registration-answers';
                }else{
                    url = config.public.BASE_URL_SOPS + '/api/clubRegistration/getQuestionsByCompanyId';
                }
                const ns = new NetworkService();
                let {IsOperationSuccessful, ErrorMessages, Result} = await ns.post$fetch(url, {CompanyId: companyId}, null, "loadAllAnswerForCurrentCompany" + new Date().getMilliseconds() + "_", false, fromServer);
                if(IsOperationSuccessful){
                    return Result;
                }else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error fetching answers',
                        message: Result+' : '+ErrorMessages,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        inviteSelectedWaitlistStudents: async function (inviteSelectedWaitlistsRB: InviteSelectedWaitlistsRB) {
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/Club/inviteSelectedWaitlistStudents';
                const ns = new NetworkService();
                let {IsOperationSuccessful, ErrorMessages, Result} = await ns.post$fetch(url, inviteSelectedWaitlistsRB , null, "inviteSelectedWaitlistStudents" + new Date().getMilliseconds() + "_", false);
                if(IsOperationSuccessful){
                    return Result;
                } else {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Error fetching answers',
                        message: Result+' : '+ErrorMessages,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    getters: {
        getClubByClubId: state => function (clubId:number) {
            return state.clubs.find((item:any)=>item.id==clubId)
        },
        getClubStudentsByClubId: state => function (clubId:number) {
           const  club = state.clubs.find((item:any)=>item.id==clubId)
            return club.studentIds
        },
        getLocationById: state => function (locationId:number) {
            return state.locationMap[locationId]
        },
        getClubCategoryById: state => function (categoryId:number) {
            return state.clubCategoryMap[categoryId]
        },
        getClubCategories: state =>  Object.values(state.clubCategoryMap) as ClubCategory[],
        getClubs: state => state.clubs,
        getOpenClubs: state => state.clubs.filter((club)=>club.Status==ClubStatus.OPEN && club.ClubType== ClubType.CLUB),
        getActiveAndArchived: state => state.clubs.filter((club)=>club.ClubType== ClubType.CLUB && club.Status==ClubStatus.OPEN || club.Status== ClubStatus.ARCHIVED ),
        getPrograms: state => state.clubs.filter((club)=>club.ClubType== ClubType.CLUB) as Club[],
        getOpenEvents: state => state.clubs.filter((club)=>club.Status==ClubStatus.OPEN && club.ClubType== ClubType.EVENT),
        getLocationList: state => Object.values(state.locationMap),
        getParticipatedEventsForLoggedInUser: state => state.participatedEventsForLoggedInUser,
        getAllEventsOnlyOfThisCompany: state => state.clubs.filter((club)=>(club.ClubType==ClubType.EVENT) && club.Status==ClubStatus.OPEN),
        getAllEventsOfThisCompany: state => state.clubs.filter((club)=>(club.ClubType==ClubType.EVENT || club.ClubType==ClubType.RSVP) && club.Status==ClubStatus.OPEN),
        getAllRsvpEventsOfThisCompany: state => state.clubs.filter((club)=>club.ClubType==ClubType.RSVP && club.Status==ClubStatus.OPEN),
        getRsvpGuestEvent: state => state.rsvpGuestEvent as Club,
        getCurrentClub: state => state.currentClub as Club,
        getAllAnswersForCurrentClub: state => state.allAnswersForCurrentClub,
        getAllAnswersForCurrentCompany: state => state.allAnswersForCurrentCompany,
        getAnswerForCurrentClubByStudentId: state => function (studentId: number) {
            try{
                return state.allAnswersForCurrentClub.get(studentId);
            }catch (e) {
                console.log(e)
            }
        },
        getStudentsOfCurrentClubByClubId: state => function (clubId: number) {
            try{
               const currentClub:Club|undefined =  state.clubs.find((club:Club)=>(club.id==clubId))
                return currentClub?.studentIds??[]
            }catch (e) {
                console.log(e)
            }
        },
        getAnswerByStudentIdAndClubId: state => function (studentId: number, clubId:number) {
            try{
                const studentAllClubAnswerMap = state.allAnswersForCurrentCompany.get(studentId);
                if(studentAllClubAnswerMap){
                    return studentAllClubAnswerMap.get(clubId);
                }
            }catch (e) {
                console.log(e)
            }
        }
    }
})